//Backend
export const IMG_PREVIEW_URL = `https://api.ozarbr.com/images/`;
export const API_BASE_URL = `https://api.ozarbr.com/`;
export const TOKENS_URL = `tokens`;
export const STATS_URL = `stats`;
export const TRANSACTIONS_URL = `transactions`;
export const COLLECTORS_URL = `collectors`;
export const TOKEN_FREQUENCY_URL = `transaction-collector-frequency`;
export const TRANSACTIONS_SERIES = `series/transactions`;

//Frontend
export const SINGLE_TOKEN_PATH = `token/`;
export const SINGLE_COLLECTOR_PATH = `collector/`;
export const COLLECTORS_PATH = `collectors`;
export const TOKENS_PATH = `tokens`;
export const TRANSACTIONS_PATH = `transactions`;
export const ABOUT_PATH = `about`;


enum Rarity {
    Unique = 1,
    Relic = 10,
    Extreme = 100,
    Very = 500,
    Rare = 1000,
    Uncommon = 10000,
    Common = 100000,
}

export const TRAIT_RARITY_LEVELS = {
    [Rarity.Unique]: {lessThan: 1, color: "#ad87ba", label: "Unique", percentage: 100},
    [Rarity.Relic]: {lessThan: 10, color: "#ad87ba", label: "Relic", percentage: 90},
    [Rarity.Extreme]: {lessThan: 100, color: "#a63131", label: "Extremely rare", percentage: 80},
    [Rarity.Very]: {lessThan: 500, color: "#f77f44", label: "Very rare", percentage: 70},
    [Rarity.Rare]: {lessThan: 1_000, color: "#f3f380", label: "Rare", percentage: 50},
    [Rarity.Uncommon]: {lessThan: 10_000, color: "#659574", label: "Uncommon", percentage: 20},
    [Rarity.Common]: {lessThan: 100_000, color: "#e6e7d4", label: "Common", percentage: 0}
}

export const traits = {
    paper: [
        {value: "Confetti", occurrence: 1, frequency: 0.00001, rarity: TRAIT_RARITY_LEVELS[Rarity.Unique]},
        {value: "Red", occurrence: 135, frequency: 0.00177, rarity: TRAIT_RARITY_LEVELS[Rarity.Very]},
        {value: "Violette", occurrence: 455, frequency: 0.00597, rarity: TRAIT_RARITY_LEVELS[Rarity.Very]},
        {value: "Orange", occurrence: 609, frequency: 0.00799, rarity: TRAIT_RARITY_LEVELS[Rarity.Rare]},
        {value: "Mint", occurrence: 688, frequency: 0.00902, rarity: TRAIT_RARITY_LEVELS[Rarity.Rare]},
        {value: "Yellow", occurrence: 2495, frequency: 0.03273, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Pure White", occurrence: 2573, frequency: 0.03376, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Pink", occurrence: 4907, frequency: 0.06438, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Black Black", occurrence: 10866, frequency: 0.14258, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Blue", occurrence: 11632, frequency: 0.15263, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Sketchbook", occurrence: 13812, frequency: 0.18124, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "White", occurrence: 13960, frequency: 0.18318, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Grey Green", occurrence: 14075, frequency: 0.18469, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]}
    ],
    margins: [
        {value: "3%", occurrence: 18088, frequency: 0.23735, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "9%", occurrence: 18111, frequency: 0.23765, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "6%", occurrence: 18183, frequency: 0.23859, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "12%", occurrence: 21826, frequency: 0.2864, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
    ],
    format: [
        {value: "Huge square", occurrence: 9, frequency: 0.00012, rarity: TRAIT_RARITY_LEVELS[Rarity.Relic]},
        {value: "Monument", occurrence: 764, frequency: 0.01002, rarity: TRAIT_RARITY_LEVELS[Rarity.Rare]},
        {value: "Wide Landscape", occurrence: 1553, frequency: 0.02037, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Large square", occurrence: 1597, frequency: 0.02095, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Portrait", occurrence: 21111, frequency: 0.27695, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Landscape", occurrence: 21439, frequency: 0.28123, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Small square", occurrence: 29735, frequency: 0.39018, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]}
    ],
    background: [
        {value: "Smolskull", occurrence: 52, frequency: 0.00068, rarity: TRAIT_RARITY_LEVELS[Rarity.Extreme]},
        {value: "Honey", occurrence: 105, frequency: 0.00138, rarity: TRAIT_RARITY_LEVELS[Rarity.Very]},
        {value: "Hearts", occurrence: 434, frequency: 0.00569, rarity: TRAIT_RARITY_LEVELS[Rarity.Very]},
        {value: "Sakura", occurrence: 802, frequency: 0.01052, rarity: TRAIT_RARITY_LEVELS[Rarity.Rare]},
        {value: "Pollen", occurrence: 1119, frequency: 0.01468, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Flowers", occurrence: 1453, frequency: 0.01906, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Strokes", occurrence: 2038, frequency: 0.02673, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Cloud", occurrence: 13974, frequency: 0.18334, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Rain", occurrence: 14012, frequency: 0.18385, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Fog", occurrence: 14042, frequency: 0.18424, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Scales", occurrence: 14082, frequency: 0.18476, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Sun", occurrence: 14095, frequency: 0.18493, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]}
    ],
    tree_choice: [
        {value: "Early birds", occurrence: 10, frequency: 0.00013, rarity: TRAIT_RARITY_LEVELS[Rarity.Relic]},
        {value: "Vingt-deux", occurrence: 100, frequency: 0.00131, rarity: TRAIT_RARITY_LEVELS[Rarity.Extreme]},
        {value: "x100", occurrence: 100, frequency: 0.00131, rarity: TRAIT_RARITY_LEVELS[Rarity.Extreme]},
        {value: "Discovery", occurrence: 1467, frequency: 0.01924, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Back in time", occurrence: 1470, frequency: 0.01928, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Normal", occurrence: 73061, frequency: 0.95864, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]}
    ],
    texture: [
        {value: "Paper", occurrence: 37931, frequency: 0.49773, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Canvas", occurrence: 38277, frequency: 0.50227, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]}
    ],
    mention: [
        {value: "Aux Amours", occurrence: 60, frequency: 0.00079, rarity: TRAIT_RARITY_LEVELS[Rarity.Extreme]},
        {value: "Graine d'arbre", occurrence: 173, frequency: 0.00227, rarity: TRAIT_RARITY_LEVELS[Rarity.Very]},
        {value: "So French", occurrence: 536, frequency: 0.00703, rarity: TRAIT_RARITY_LEVELS[Rarity.Rare]},
        {value: "Aux Arbres", occurrence: 3779, frequency: 0.04959, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Artiste", occurrence: 5726, frequency: 0.07513, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Numéroté", occurrence: 9665, frequency: 0.12681, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "_", occurrence: 56269, frequency: 0.73836, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]}
    ],
    bark: [
        {value: "Doodle dashed", occurrence: 2438, frequency: 0.03198, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Waves dashed", occurrence: 4779, frequency: 0.06271, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Grain", occurrence: 12588, frequency: 0.16518, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Doodle", occurrence: 18613, frequency: 0.24422, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Waves", occurrence: 37790, frequency: 0.49583, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]}
    ],
    thread_flow: [
        {value: "Round", occurrence: 9341, frequency: 0.12258, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Ring", occurrence: 9544, frequency: 0.12525, rarity: TRAIT_RARITY_LEVELS[Rarity.Uncommon]},
        {value: "Vertical Flow", occurrence: 19034, frequency: 0.24984, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Flow", occurrence: 19121, frequency: 0.25100, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Horizontal Flow", occurrence: 19168, frequency: 0.25152, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]}

    ],
    thread: [
        {value: "Tangled", occurrence: 10301, frequency: 0.13518, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Double", occurrence: 10335, frequency: 0.13563, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Sewing", occurrence: 13778, frequency: 0.18081, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Dashed", occurrence: 13849, frequency: 0.18175, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]},
        {value: "Single", occurrence: 27945, frequency: 0.36669, rarity: TRAIT_RARITY_LEVELS[Rarity.Common]}
    ]
}