import {Stack} from "@mui/material";
import * as React from "react";
import {Token} from "../../models/token";
import Typography from "@mui/material/Typography";
import {formatEth} from "../../utils/helpers";
import Spacer from "../Spacer";
import {useProps} from "@mui/x-data-grid/internals";

export default function TokenOverlay(props: { token: Token }) {
    const {token} = useProps(props);
    return (<>
            <Stack direction={"row"} sx={{margin: 0}}>
                <Typography variant={"body1"} sx={{minWidth: "100px"}}> #{token.id}</Typography>
                <Spacer/>
                {(token.last_price != null && token.last_price > 0) &&
                    <Typography variant={"body1"}> {formatEth(BigInt(token.last_price))}</Typography>}

            </Stack>
            <Stack direction={"row"} sx={{paddingLeft: "10px", margin: 0}}>
                <Spacer/>
                {/*<Typography variant={"body1"} >Rarity Rank: {token.rarityRank}</Typography>*/}
            </Stack>
        </>
    );
}
