import {Box, Link, List, ListItemButton, ListItemText} from "@mui/material";
import {traits} from "../../../utils/constants";
import * as React from "react";

export default function PaperTooltip(props: { selectedMargins: string | undefined }) {
    return <Box width={"300px"}>
        <List sx={{padding: 0}}>

            {traits.margins.map(margin => {
                return (
                    <ListItemButton key={margin.value}
                                    component={Link}
                                    href={`/tokens?order_by=id&margins=${(traits.margins.find(m => m.value === margin.value))?.value}`}
                                    disableGutters
                                    sx={{
                                        marginLeft: (margin.value == props.selectedMargins) ? "-9px" : 0,
                                        width: "100%",
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        backgroundColor: (margin.value == props.selectedMargins) ? "grey" : "inherit"
                                    }}>
                        <ListItemText primary={margin.value}
                                      sx={{
                                          width: "40px",
                                          marginLeft: (margin.value == props.selectedMargins) ? "15px" : 0
                                      }}
                        />
                        <ListItemText primary={String(margin.occurrence)}
                        />
                    </ListItemButton>
                )
            })}
        </List>
    </Box>;
}
