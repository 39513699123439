import * as React from 'react';
import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import ColorBoxes from "../ColorBoxes";
import {Box, Link} from "@mui/material";
import {grey, yellow} from "@mui/material/colors";
import TokensPreview from "./sections/TokensPreview";
import styled from "@mui/system/styled";
import TransactionsPreview from "./sections/TransactionsPreview";
import CollectorsPreview from "./sections/CollectorsPreview";
import Conclusions from "./sections/Conclusions";
import {API_BASE_URL, STATS_URL} from "../../utils/constants";
import {ApiResponse} from "../../models/ApiResponse";
import {Stats} from "../../models/stats";

const Highlight = styled('span')(({theme}) => ({
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.light
    }
}));

export default function Homepage() {
    const [totalTrades, setTotalTrades] = useState(5000);
    const [totalCollectors, setTotalCollectors] = useState(43664);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetch(`${API_BASE_URL}${STATS_URL}`)
            .then(response => response.json())
            .then((response: ApiResponse<Stats>) => {
                setTotalTrades(response.data.trades);
                setTotalCollectors(response.data.collectors);
                setIsLoading(false);
            })
            .catch(e => console.error(e));
    }, []);

    return (<>
            <Grid container spacing={3} height={'100%'} sx={{position: 'relative', overflow: 'hidden'}}>
                <ColorBoxes maxHeight={500}/>
                <Grid container rowSpacing={1} padding={'80px 40px'} zIndex={1}>
                    <Grid size={{xs: 12, sm: 12, md: 12}}>
                        <Box>
                            <Typography variant="h4" sx={{backgroundColor: grey.A100, padding: '1rem'}}>In February
                                2024, <strong>Zancan</strong> released his largest NFT collection of generative
                                art <strong>Aux&nbsp;Arbres</strong><span
                                    style={{backgroundColor: yellow.A200}}>*</span>.&#32;
                                <Link href={"/collectors"} variant="h4" underline="none" color="inherit">
                                   &nbsp;<Highlight>{totalCollectors.toLocaleString()} collectors
                                    </Highlight></Link>&nbsp;
                                minted a total of<br/>
                                <Link href={"/tokens"} variant="h4" underline="none" color="inherit">
                                    <Highlight>76,208 unique tokens</Highlight></Link>.
                                The release
                                was a part of the
                                launch of Base blockchain in France. Since then, there have been<br/>
                                <Link href={"/transactions"} variant="h4" underline="none"
                                      color="inherit"><Highlight>{totalTrades.toLocaleString()} transactions</Highlight></Link> involving
                                the tokens. Here, we will explore this exceptional collection.</Typography>

                            <Typography variant="subtitle2"><span style={{backgroundColor: yellow.A200}}><sup>*</sup> it is French for "To the trees". The token's ticker is
                                the phonetic transcription "OZARBR".</span></Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>


            <TokensPreview/>
            <TransactionsPreview/>
            <CollectorsPreview/>
            <Conclusions/>
        </>
    );
}
