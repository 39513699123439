import {ButtonGroup} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import XIcon from "@mui/icons-material/X";

export default function MainMenu() {
    return (
        <ButtonGroup variant="text" aria-label="Basic button group">
            <Button size='large' href={'/tokens'}>Tokens</Button>
            <Button size='large' href={'/transactions'}>Transactions</Button>
            <Button size='large' href={'/collectors'}>Collectors</Button>
            <Button startIcon={<XIcon/>} variant="text" color="secondary"
                    href={"https://x.com/BanzaiTokyo"} disableElevation target={'_blank'}
                    sx={{paddingLeft: '20px'}}>
                BanzaiTokyo
            </Button>
        </ButtonGroup>
    );
}