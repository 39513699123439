import * as React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Alert,
    Breadcrumbs,
    Card,
    CardContent,
    Chip,
    Divider,
    Link,
    Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import ColorBoxes from "../ColorBoxes";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TOKENS_PATH, traits} from "../../utils/constants";
import {useNavigate} from "react-router-dom";

export default function AboutPage() {
    const navigate = useNavigate();

    function selectTrait(trait: string, value: any) {
        navigate(`/${TOKENS_PATH}?${trait}=${value}`);
    }

    return (
        <>
            <Grid container spacing={3} height={'100%'} sx={{position: 'relative', overflow: 'hidden', height: '10px'}}>
                <ColorBoxes maxHeight={10}/>
            </Grid>
            <Card>
                <CardContent>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Home
                        </Link>
                        <Typography sx={{color: 'text.primary'}}>About OZARBR</Typography>
                    </Breadcrumbs>

                    <Grid container spacing={2}>
                        <Grid size={{xs: 12, sm: 4, md: 4}}>
                        </Grid>
                        <Grid size={{xs: 12, sm: 8, md: 8}}>
                            <Alert variant="outlined" severity="warning">
                                Please note that this page and the website as a whole is work in progress and is regularly updated.
                            </Alert>
                            <p><Typography variant={'h6'}>About Aux Arbres and OZARBR</Typography></p>

                            <p><Link href={'https://zancan.art/'}> Zancan</Link> has achieved remarkable success on the
                                Tezos blockchain, with NFT sales <Link href={'https:BigOnTezos.com'}>exceeding 3,000,000
                                    XTZ</Link>. His collections, such as "Garden, Monoliths" and "The
                                Lushtemples," have garnered significant attention and acclaim within the digital art
                                community. He has been featured in many publications on generative art including an
                                iconic <Link
                                    href={'https://www.taschen.com/fr/limited-editions/art/60080/on-nfts-collector-s-edition'}> "On
                                    NFTs"</Link> book published by Taschen in 2024.
                            </p>
                            <p> In February 2024, Zancan launched "Aux Arbres". It is by far his most
                                extensive generative art collection to date, comprising 76,208 unique tokens. This
                                collection was released on the Base blockchain as part of Base's promotional campaign in
                                France.
                            </p>
                            <p><Link href={'https://www.coinbase.com/en-fr/blog/introducing-base'}>Base</Link> is an
                                Ethereum Layer 2 (L2) network developed by Coinbase. It is designed to make on-chain
                                development more accessible, cost-effective, and secure for both developers and users.
                                In their own words: <i>Base is a secure, low-cost, developer-friendly Ethereum L2 built
                                    to bring the next billion users to web3.</i>
                            </p>

                            <p>The minting of tokens was open for "free" for nearly two weeks on <Link
                                href={'https://highlight.xyz/mint/base:0x26a1F8813dF5a318Ed7aA1091C30dB0f25727a18'}>highlight.xyz</Link>.
                                However,
                                collectors were required to pay a "platform fee" of 0.0008 ETH (approximately $2) per
                                NFT minted. Each wallet was limited to a maximum of 100 mints. From February 20th to
                                March 3rd, 2024, anyone could mint a uniquely generated NFT. The project attracted
                                participation from over 43,000 collectors.</p>

                            <p>All indications suggest that the collection is truly exceptional. The marketing support
                                from Base, Highlight, and Zancan himself ensured that a significant number of collectors
                                minted an impressive array of unique generative artworks. Despite this, the collection
                                has not delivered extraordinary performance. Aux Arbres has achieved a total trading
                                volume of only 18 ETH across approximately 5,500 trades.
                            </p>
                            <p>This website offers a visual and data-driven exploration of the Aux Arbres NFT
                                collection. It is designed to help you understand the organization of token traits, view
                                the artwork, delve into transaction details, and discover the community of collectors.
                            </p>
                            <Divider/>

                            <p><Typography variant={'h6'}>Possible evolution</Typography></p>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/soixante-seize-mille-deux-cent-huit-aux-arbres.png`}
                                alt={'Soixante-seize mille deux cent huit Aux Arbres'}/>
                            <p>Zancan has already released <Link
                                href={'https://zancan.art/Works/56-76208-aux-arbres/9762/all-aux-arbres'}
                            >Soixante-seize mille deux cent huit Aux Arbres</Link>, a compilation of the miniatures of
                                all the images in the original collection organized into a 15960×8977 pixel image. This
                                image is available as a 100 editions NFT for collectors of original Aux Arbres with 50+
                                tokens. Still more than half of the editions are available.
                            </p>

                            <p>Several elements suggest that Aux Arbres is still a work in progress and may undergo
                                further development. The visual style of the images represents a departure from Zancan's
                                signature plotter art, hinting at an exploration of embroidery-style physical
                                renderings. While it remains uncertain whether many embroidery pieces will be created
                                from these visuals, at least one such piece has already been <Link
                                    href={'https://x.com/Greekdx/status/1854512606272201183'}>reported by a
                                    collector</Link>. Who received the physical rendering of his <Link
                                    href={'/token/22057'}> #22057</Link>.</p>
                            <p>Some of the token traits also suggest embroidery theme, namely <i>Thread</i> and <i>Thread
                                flow</i>.
                            </p>

                            <Accordion defaultExpanded={false}>
                                <AccordionSummary
                                    sx={{minHeight: "0px", ".MuiAccordionSummary-content": {margin: 0},}}
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography gutterBottom variant="body2">
                                        The traits
                                        are: <strong>Paper, Margins, Format, Background, Tree choice, Texture, Mention,
                                        Bark, Thread flow, Thread</strong>

                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p>The possible values of traits are presented below. In Parenthesis you can see the
                                        number of occurences of each value.</p>
                                    <Typography variant={"h6"}>Paper: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.paper.map(paper => {
                                            return (<Chip
                                                key={paper.value}
                                                label={`${paper.value} (${paper.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("paper", paper.value)}
                                            />)
                                        })}

                                    </Stack>

                                    <Typography variant={"h6"}>Margins: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.margins.map(margin => {
                                            return (<Chip
                                                key={margin.value}
                                                label={`${margin.value} (${margin.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("margins", margin.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Format: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.format.map(format => {
                                            return (<Chip
                                                key={format.value}
                                                label={`${format.value} (${format.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("format", format.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Background: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.background.map(background => {
                                            return (<Chip
                                                key={background.value}
                                                label={`${background.value} (${background.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("background", background.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Tree choice: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.tree_choice.map(treeChoice => {
                                            return (<Chip
                                                key={treeChoice.value}
                                                label={`${treeChoice.value} (${treeChoice.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("tree_choice", treeChoice.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Texture: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.texture.map(texture => {
                                            return (<Chip
                                                key={texture.value}
                                                label={`${texture.value} (${texture.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("texture", texture.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Mention: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.mention.map(mention => {
                                            return (<Chip
                                                key={mention.value}
                                                label={`${mention.value} (${mention.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("mention", mention.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Bark: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.bark.map(bark => {
                                            return (<Chip
                                                key={bark.value}
                                                label={`${bark.value} (${bark.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("bark", bark.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Thread Flow: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.thread_flow.map(flow => {
                                            return (<Chip
                                                key={flow.value}
                                                label={`${flow.value} (${flow.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("thread_flow", flow.value)}
                                            />)
                                        })}
                                    </Stack>

                                    <Typography variant={"h6"}>Thread: </Typography>

                                    <Stack
                                        spacing={{xs: 1}}
                                        direction="row"
                                        useFlexGap
                                        sx={{flexWrap: 'wrap', paddingBottom: "10px"}}
                                    >
                                        {traits.thread.map(thread => {
                                            return (<Chip
                                                key={thread.value}
                                                label={`${thread.value} (${thread.occurrence})`}
                                                component="a"
                                                variant="outlined"
                                                clickable
                                                onClick={() => selectTrait("thread", thread.value)}
                                            />)
                                        })}
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>


                            <p><Typography variant={'h6'}>Rarity</Typography></p>

                            <p>The values of traits vary in number and rarity (frequency of occurrence), and the
                                combinations of these trait values result in a multitude of unique arrangements. In
                                fact, the vast majority of tokens are unique in their trait combinations, with only
                                around 20 tokens sharing identical traits.</p>

                            <p> This uniqueness makes it challenging to classify the NFTs by rarity. A simple approach I
                                initially implemented involved summing the relative frequencies of trait values and
                                considering tokens with the lowest totals as the rarest. However, this method doesn't
                                yield satisfactory results. It often elevates tokens with several moderately rare traits
                                to the top, while clearly rare NFTs — such as <Link
                                    href={'/token/73774'}> #73774</Link> — end up ranked much lower.</p>

                            <p><Link href={'/token/73774'}>#73774</Link> is the only token in the collection with the
                                "Confetti" value for the "Paper" trait, making it truly one of a kind. No other trait in
                                the collection has a single occurrence! Yet, under this method, such an obviously rare
                                NFT is undervalued in the ranking.</p>

                            <p> This issue highlights the need for a more sophisticated approach, perhaps incorporating
                                weighted ratios or other methods to better account for extreme rarity. <Link
                                    href={'https://x.com/banzaitokyo'}>I am open to suggestions</Link> on how to refine
                                this rarity classification system.</p>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
        ;
}
