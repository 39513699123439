import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {Box, Breadcrumbs, Card, CardContent, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import ColorBoxes from "../ColorBoxes";
import PaginatedTable from "../common/PaginatedTable";
import {GridColDef, GridRenderCellParams, GridSortModel} from "@mui/x-data-grid";
import {API_BASE_URL, COLLECTORS_URL, SINGLE_COLLECTOR_PATH} from "../../utils/constants";
import {cutTheMiddle, formatEth} from "../../utils/helpers";
import {ApiResponse} from "../../models/ApiResponse";
import {useSearchParams} from "react-router-dom";
import {Collector} from "../../models/collector";

export default function CollectorsPage() {
    const [searchParams, _setSearchParams] = useSearchParams();

    const [totalRowCount, setTotalRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [loadedCollectors, setLoadedCollectors] = useState<Collector[]>([]);

    const sortModel = useMemo<GridSortModel | undefined>(() => {
        const orderParam = searchParams.get('order_by') || '-total_tokens';
        if (orderParam == null) return;
        const order = orderParam.substring(0, 1) === '-' ? 'desc' : 'asc'
        return [{field: order === "asc" ? orderParam : orderParam.substring(1, orderParam.length), sort: order}];
    }, [searchParams]);

    const paginationModel = useMemo<{ pageSize: number; page: number; }>(() => {
        return {
            pageSize: Number(searchParams.get('perPage')) || 25,
            page: parseInt(searchParams.get('page') || '0', 10),
        }
    }, [searchParams]);

    useEffect(() => {
        function getOrderByParam() {
            if (sortModel == null || sortModel.length == 0) return "";
            const orderBy = sortModel[0].sort === "asc" ? sortModel[0].field : "-" + sortModel[0].field;
            return `&order_by=${orderBy}`;
        }

        setIsLoading(true);

        const offset = `offset=${paginationModel.page * paginationModel.pageSize}`;
        const limit = `&limit=${paginationModel.pageSize}`;
        const orderByParam = getOrderByParam();
        fetch(`${API_BASE_URL}${COLLECTORS_URL}?${offset}${orderByParam}${limit}`)
            .then(response => response.json())
            .then((response: ApiResponse<Collector[]>) => {
                setTotalRowCount(Number(response.pagination.totalRowCount));
                const transformedData = response.data.map((collector: Collector, index: number) => {
                    return {...collector, id: index}
                })
                setLoadedCollectors(transformedData);
                setIsLoading(false);
            });
    }, [paginationModel.page, paginationModel.pageSize, sortModel]);

    const columns: GridColDef<(typeof loadedCollectors)[number]>[] = [
        {
            field: 'id',
            headerName: ' id',
            width: 30,
        },
        {
            field: 'wallet',
            headerName: 'Collector',
            width: 200,
            editable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <a href={`/${SINGLE_COLLECTOR_PATH}${params.row.wallet}`}>
                        {`${params.row.eth_domain || cutTheMiddle(params.row.wallet)}`}
                    </a>
                </Box>
            ),
        },
        {
            field: 'total_tokens',
            headerName: 'Total Tokens',
            sortable: true,
            flex: 0.5,
            width: 100,
            valueGetter: (_value: any, row: Collector) => row.total_tokens || 0,
        },
        {
            field: 'twitter',
            headerName: 'X.com',
            width: 200,
            editable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <a href={`https://x.com/${params.row.twitter}`} target={'_blank'}>
                        {`${params.row.twitter !=null ? '@'+ params.row.twitter : ''}`}
                    </a>
                </Box>
            ),
        },
        {
            field: 'owns_soixante',
            headerName: 'soixante',
            renderHeader: () => <a target={'_blank'}
                                   href={`https://highlight.xyz/mint/ethereum:0xfc1ce875D45923001074E247dFd4232d5DC23447:0?referrer=0xBf677072b6b4AaDE1dd2895Be9A099088352c30D`}>
                <img
                    alt="token preview"
                    height={50}
                    src={`${process.env.PUBLIC_URL}/images/soixante.png`}
                    loading="lazy"
                /></a>,
            sortable: true,
            flex: 0.5,
            width: 100,
            valueGetter: (_value: any, row: Collector) => row.owns_soixante ? '☑️' : '',
        },
        {
            field: 'total_value',
            headerName: 'Total Value',
            sortable: true,
            flex: 1,
            width: 100,
            valueGetter: (_value: any, row: Collector) => formatEth(BigInt(row.total_value || 0)),
        },
    ];

    return (
        <>
            <Grid container spacing={3} height={'100%'} sx={{position: 'relative', overflow: 'hidden', height: '10px'}}>
                <ColorBoxes maxHeight={10}/>
            </Grid>
            <Card>
                <CardContent>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Home
                        </Link>
                        <Typography sx={{color: 'text.primary'}}>Collectors</Typography>
                    </Breadcrumbs>


                </CardContent>

                <PaginatedTable columns={columns}
                                data={loadedCollectors}
                                isLoading={isLoading}
                                paginationModel={paginationModel}
                                sortModel={sortModel}
                                totalRowCount={totalRowCount}
                                showId={false}
                />
            </Card>


        </>
    );
}
