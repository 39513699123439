import * as React from "react";
import {Card, CardActions, Divider, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import XIcon from "@mui/icons-material/X";
import {ABOUT_PATH} from "../../../utils/constants";


export default function Conclusions() {
    return (
        <>
            <Divider sx={{paddingTop: "20px"}} id={"conclusion"}>
                <Typography variant={"button"}> Why this exploration exists?</Typography>
            </Divider>

            <Card>
                <CardActions>
                    <Grid container width={"100%"} columns={{xs: 12, sm: 12, md: 12}} spacing={3}>
                        <Grid size={{xs: 12, sm: 8, md: 8}} paddingLeft={"10px"}>
                            <Typography>Aux Arbres is a fascinating collection for several reasons. Not only is it one
                                of the largest generative art NFT collections globally, but it is also the most
                                extensive project created by Zancan to date. Zancan, a prominent artist both in France
                                and internationally, has consistently produced work that captivates audiences, and this
                                collection marks a significant milestone in his artistic journey. What makes Aux Arbres
                                particularly noteworthy is that it represents Zancan's first major experiment on the
                                Base blockchain. Zancan has primarily worked on the Tezos blockchain. It’s intriguing to
                                see him explore a multichain approach, leaving us to wonder where this new direction
                                will take him and his future projects.
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 4, md: 4}} display="flex" alignItems="flex-start">
                            <Stack sx={{width: '100%'}}>
                                <Button variant="contained" disableElevation href={`/${ABOUT_PATH}`}>
                                    Read more about OZARBR
                                </Button>
                                <br/>
                                <br/>
                                <Typography variant={'h6'} sx={{paddingBottom: '10px'}}>get in touch and share your
                                    thoughts:</Typography>
                                <Button startIcon={<XIcon/>} variant="contained" color="secondary"
                                        href={"https://x.com/BanzaiTokyo"} disableElevation target={'_blank'}>
                                    BanzaiTokyo
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>

                </CardActions>
            </Card>
        </>
    );
}
