import * as React from "react";
import {Card, CardActions, CardContent, Divider, ImageListItem, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import theme from "../../../theme";
import {useNavigate} from "react-router-dom";
import {SINGLE_TOKEN_PATH, TOKENS_PATH} from "../../../utils/constants";

const squareImages = [
    {fileName: "orange.png", id: "66556"},
    {fileName: "pure-white.png", id: "12050"},
    {fileName: "red.png", id: "60000"},
    {fileName: "yellow.png", id: "10000"},
    {fileName: "sketchbook.png", id: "10001"},
    {fileName: "pink.png", id: "10006"},
    {fileName: "white.png", id: "1"},
    {fileName: "grey-green.png", id: "10034"},
    {fileName: "violette.png", id: "60834"},
    {fileName: "mint.png", id: "58909"},
    {fileName: "blue.png", id: "10017"},
    {fileName: "black-black.png", id: "10031"}
];

const ImageListItemWithHover = styled(ImageListItem)(() => ({
    "&:hover": {
        cursor: "pointer",
        opacity: 0.8,
    },

}));
const ImageListResponsive = styled(ImageListItem)(() => ({
    display: 'grid',
    padding: 0,
    gap: 8,
    [theme.breakpoints.up('xs')]: {
        gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(6, 1fr)'
    },
    [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(6, 1fr)'
    },
}));

export default function TokensPreview() {

    const navigate = useNavigate();

    const cardSelected = (event: React.MouseEvent, value: any) => {
        navigate(`/${SINGLE_TOKEN_PATH}${value.id}`);
    };
    return (
        <>
            <Divider sx={{paddingTop: "20px"}} id={"tokens"}>
                <Typography variant={"button"}> Tokens</Typography>
            </Divider>
            <Card>
                <CardContent>
                    <ImageListResponsive>
                        {squareImages.map((item) => (
                            <ImageListItemWithHover key={item.fileName}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/squares/${item.fileName}`}
                                    loading="lazy"
                                    alt={item.fileName}
                                    onClick={(e) => cardSelected(e, item)}
                                />
                            </ImageListItemWithHover>
                        ))}
                    </ImageListResponsive>
                </CardContent>
                <CardActions>
                    <Grid container width={"100%"} columns={{xs: 12, sm: 12, md: 12}} spacing={3}>
                        <Grid size={{xs: 12, sm: 8, md: 8}} paddingLeft={"10px"}>
                            <Typography>
                                The tokens have a variety of traits, some of which are quite straightforward, such as
                                "paper," representing image color (as shown in the examples above), "format," and
                                "margins." Others represent visual aspects of the image, like the "background".
                                Additionally, there are two traits, "thread flow" and "thread," which reflect the
                                embroidery theme of the project. "Tree id" and "Tree choice" — are quite cryptic. They
                                seem to suggest that the project may evolve further to give them more
                                significance. </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 4, md: 4}} display="flex" alignItems="flex-start">
                            <Stack sx={{width: '100%'}}>
                                <Button variant="contained" disableElevation href={`/${TOKENS_PATH}`}>
                                    Browse tokens and their traits
                                </Button>
                            </Stack>

                        </Grid>
                    </Grid>

                </CardActions>
            </Card>
        </>

    );
}
