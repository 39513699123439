import * as React from "react";
import {useEffect, useState} from "react";
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import theme from "../../../theme";
import {Box, Card, CardActions, CardContent, Divider, LinearProgress, Stack, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import {API_BASE_URL, TRANSACTIONS_PATH, TRANSACTIONS_SERIES} from "../../../utils/constants";
import {ApiResponse} from "../../../models/ApiResponse";
import {TransactionsTimeseriesUnit} from "../../../models/transactionsTimeseriesUnit";
import {transformTransactionTimeseries} from "../../../utils/helpers";

const proportionDesktop = 4;

function WeeklyTooltip(pl: any) {
    const {active, payload} = pl;

    if (active && payload?.[0]?.payload != null) {
        const pld = payload[0].payload;
        return (<>
            <Typography
                sx={{paddingLeft: 1}}> Week of: {`${new Date(pld.date).toLocaleDateString()}`}</Typography>
            <Typography
                sx={{paddingLeft: 1, color: theme.palette.secondary.main}}> Transactions: {`${pld.sales}`}</Typography>
            <Typography sx={{
                paddingLeft: 1,
                color: theme.palette.primary.main
            }}> Volume: {`${pld.formattedVolume}`}</Typography>
        </>);
    }
    return null;
}

const formatXAxis = (week: string) => {
    const d = new Date(week);
    return d.toLocaleString('default', {month: 'long'});
};

export default function TransactionsPreview() {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [isLoading, setIsLoading] = useState(false);
    const [loadedData, setLoadedData] = useState<TransactionsTimeseriesUnit[]>([]);

    useEffect(() => {
        setIsLoading(true);
        fetch(`${API_BASE_URL}${TRANSACTIONS_SERIES}`)
            .then(response => response.json())
            .then((response: ApiResponse<TransactionsTimeseriesUnit[]>) => {
                setLoadedData(transformTransactionTimeseries(response.data));
                setIsLoading(false);
            })
            .catch(e => console.error(e)).finally(() => setIsLoading(false));

    }, []);

    return (
        <>
            <Divider sx={{paddingTop: "20px"}} id={"transactions"}>
                <Typography variant={"button"}> Transactions</Typography>
            </Divider>

            <Card>
                <CardContent sx={{paddingTop: 0, paddingBottom: 0}}>
                    <Box component="section" sx={{width: "100%", color: theme.palette.primary.light}}>
                        <ResponsiveContainer width="99%" aspect={isMobile ? 1.5 : proportionDesktop}>
                            {isLoading ? <LinearProgress/> :
                                <LineChart height={200} data={loadedData}
                                           margin={{top: 5, right: 40, left: 40, bottom: 5}}>
                                    <YAxis yAxisId="left" hide/>
                                    <YAxis yAxisId="right" orientation="right" hide/>

                                    <Line
                                        name="Transactions by week"
                                        dataKey="transactions"
                                        type="linear"
                                        yAxisId="left"
                                        stroke={theme.palette.secondary.main}
                                        isAnimationActive={false}
                                    />
                                    <Line
                                        name="Trade volume"
                                        dataKey="volume"
                                        type="linear"
                                        yAxisId="right"
                                        stroke={theme.palette.primary.main}
                                        isAnimationActive={false}
                                    />
                                    <XAxis dataKey="date" tickFormatter={formatXAxis} interval={isMobile ? 7 : 3}/>
                                    <Legend verticalAlign="middle"/>

                                    <Tooltip content={WeeklyTooltip}/>
                                </LineChart>}
                        </ResponsiveContainer>
                    </Box>

                </CardContent>
                <CardActions>
                    <Grid container width={"100%"} columns={{xs: 12, sm: 12, md: 12}} spacing={3}>
                        <Grid size={{xs: 12, sm: 8, md: 8}} paddingLeft={"10px"}>
                            <Typography>We can observe some initial activity where tokens were transferred
                                and traded.
                                However, a few months after the release, the activity appears to have
                                quieted down
                                significantly. On the transactions page, we will examine the number of
                                trades versus
                                transfers, as well as the trade volumes and the prices of the highest sales.
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 4, md: 4}} display="flex" alignItems="flex-start">
                            <Stack sx={{width: '100%'}}>
                                <Button variant="contained" disableElevation href={`/${TRANSACTIONS_PATH}`}>
                                    Explore transactions in detail
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>

                </CardActions>
            </Card>


        </>
    );
}
