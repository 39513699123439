import {Box, Link, List, ListItemButton, ListItemText} from "@mui/material";
import {traits} from "../../../utils/constants";
import * as React from "react";

export default function ThreadFlowTooltip(props: { selectedThreadFlow: string | undefined }) {
    return <Box width={"300px"}>
        <List sx={{padding: 0}}>

            {traits.thread_flow.map(threadFlow => {
                return (
                    <ListItemButton component={Link}
                                    href={`/tokens?order_by=id&thread_flow=${(traits.thread_flow.find(tf => tf.value === threadFlow.value))?.value}`}
                                    disableGutters
                                    sx={{
                                        marginLeft: (threadFlow.value == props.selectedThreadFlow) ? "-9px" : 0,
                                        width: "100%",
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        backgroundColor: (threadFlow.value == props.selectedThreadFlow) ? "grey" : "inherit"
                                    }}>
                        <ListItemText primary={threadFlow.value}
                                      sx={{
                                          width: "40px",
                                          marginLeft: (threadFlow.value == props.selectedThreadFlow) ? "15px" : 0
                                      }}
                        />
                        <ListItemText primary={String(threadFlow.occurrence)}
                        />
                    </ListItemButton>
                )
            })}
        </List>
    </Box>;
}
