import Typography from "@mui/material/Typography";
import Spacer from "./Spacer";
import MainMenu from "./MainMenu";
import * as React from "react";
import {Link, Stack} from "@mui/material";

export default function Header() {
    return (<Stack direction={{ xs: 'column', md: 'row' }}>
            <Typography variant="h4"><Link href={"/"} sx={{color: "inherit", paddingTop: "10px"}}
                                           underline="none">OZARBR</Link>
                <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{color: 'text.secondary'}}
                >Exploration of "Aux Arbres" NFTs</Typography>
            </Typography>

            <Spacer/>
            <MainMenu/>
        </Stack>
    );
}