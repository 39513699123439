import {Box, Card, CardContent, Switch} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
    API_BASE_URL,
    IMG_PREVIEW_URL,
    SINGLE_COLLECTOR_PATH,
    SINGLE_TOKEN_PATH,
    TRANSACTIONS_URL
} from "../../utils/constants";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ApiResponse} from "../../models/ApiResponse";
import {cutTheMiddle, formatEth} from "../../utils/helpers";
import {useProps} from "@mui/x-data-grid/internals";
import {Transaction} from "../../models/transaction";
import {GridColDef, GridRenderCellParams, GridSortModel} from "@mui/x-data-grid";
import PaginatedTable from "../common/PaginatedTable";

interface CollectorsTokensProps {
    wallet: string;
}

export default function TransactionsTab(props: CollectorsTokensProps) {
    const {wallet} = useProps(props);

    const [isLoading, setIsLoading] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [isOnlySales, setIsOnlySales] = useState(false);
    const [loadedTransactions, setLoadedTransactions] = useState<Transaction[]>([]);

    const [searchParams, _setSearchParams] = useSearchParams();

    const sortModel = useMemo<GridSortModel | undefined>(() => {
        const orderParam = searchParams.get('order_by') || '-timestamp';
        if (orderParam == null) return;
        const order = orderParam.substring(0, 1) === '-' ? 'desc' : 'asc'
        return [{field: order === "asc" ? orderParam : orderParam.substring(1, orderParam.length), sort: order}];
    }, [searchParams]);

    const paginationModel = useMemo<{ pageSize: number; page: number; }>(() => {
        return {
            pageSize: Number(searchParams.get('perPage')) || 25,
            page: parseInt(searchParams.get('page') || '0', 10),
        }
    }, [searchParams]);

    useEffect(() => {
        function getOrderByParam() {
            if (sortModel == null || sortModel.length == 0) return "";
            const orderBy = sortModel[0].sort === "asc" ? sortModel[0].field : "-" + sortModel[0].field;
            return `&order_by=${orderBy}`;
        }

        const isOnlySalesParam: string = isOnlySales ? "&sales_only=true" : "";
        setIsLoading(true);

        const offset = `offset=${paginationModel.page * paginationModel.pageSize}`;
        const limit = `&limit=${paginationModel.pageSize}`;
        const orderByParam = getOrderByParam();

        fetch(`${API_BASE_URL}${TRANSACTIONS_URL}?${offset}&wallet=${wallet}${orderByParam}${limit}${isOnlySalesParam}`)
            .then(response => response.json())
            .then((response: ApiResponse<Transaction[]>) => {
                setTotalRowCount(Number(response.pagination.totalRowCount));
                setLoadedTransactions(response.data.map((tr) => {
                    return {...tr, timestamp: new Date(tr.timestamp)}
                }));
                setIsLoading(false);
            });
    }, [paginationModel.page, paginationModel.pageSize, sortModel, isOnlySales]);

    const handleIsOnlySalesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsOnlySales(event.target.checked);
    };


    const columns: GridColDef<(typeof loadedTransactions)[number]>[] = [
        {
            field: 'id',
            headerName: ' id',
            width: 30,
        },
        {
            field: 'token_id',
            headerName: 'Token id',
            width: 65,
        },
        {
            field: 'preview',
            headerName: 'Preview',
            width: 100,
            editable: false,
            align: "center",
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <a href={`/${SINGLE_TOKEN_PATH}${params.row.token_id}`}>
                        <img
                            alt="token preview"
                            height={50}
                            src={`${IMG_PREVIEW_URL}${params.row.token_id}.jpg`}
                            loading="lazy"
                        /></a>
                </Box>
            ),
        },
        {
            field: 'sender',
            headerName: 'Sender',
            width: 100,
            editable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <a href={`/${SINGLE_COLLECTOR_PATH}${params.row.sender.wallet}`}>
                        {`${params.row.sender.eth_domain || cutTheMiddle(params.row.sender.wallet)}`}
                    </a>
                </Box>
            ),
        },
        {
            field: 'received',
            headerName: 'Receiver',
            width: 100,
            editable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <a href={`/${SINGLE_COLLECTOR_PATH}${params.row.receiver.wallet}`}>
                        {`${params.row.receiver.eth_domain || cutTheMiddle(params.row.receiver.wallet)}`}
                    </a>
                </Box>
            ),
        },
        {
            field: 'event',
            headerName: 'Event',
            sortable: true,
            flex: 1,
            width: 100,
        },
        {
            field: 'timestamp',
            headerName: 'Date',
            sortable: true,
            flex: 1,
            width: 160,
            valueGetter: (_value: any, row: Transaction) => row.timestamp.toLocaleDateString() + ' ' + row.timestamp.toLocaleTimeString(),
        },
        {
            field: 'price',
            headerName: 'Amount',
            sortable: true,
            flex: 1,
            width: 160,
            valueGetter: (_value: any, row: Transaction) => formatEth(BigInt(row.price || 0)),
        },
    ];

    return (
        <>
            <Card>
                <CardContent>
                    <Paper variant={"outlined"}
                           sx={{
                               display: 'flex',
                               justifyContent: 'flex-start',
                               flexWrap: 'wrap',
                               listStyle: 'none',
                               flexGrow: 1,
                               p: 0.5,
                               m: 0,
                               alignItems: "center"
                           }}
                    >
                        <Typography variant={"subtitle1"} paddingLeft={"10px"}>Only sales</Typography> <Switch
                        checked={isOnlySales}
                        onChange={handleIsOnlySalesChanged}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                    </Paper>
                </CardContent>

                <PaginatedTable columns={columns}
                                data={loadedTransactions}
                                isLoading={isLoading}
                                paginationModel={paginationModel}
                                sortModel={sortModel}
                                totalRowCount={totalRowCount}
                                showId={false}
                />

            </Card>
            <br/>
        </>
    );
}