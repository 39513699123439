import {createTheme} from '@mui/material/styles';
import {grey, red} from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
    spacing: 8,
    cssVariables: true,
    typography: {
        h4: {
            fontSize: '2.2rem',
            color: grey.A700,
            lineHeight: 1.2,
        },
        subtitle2: {
            fontSize: '1rem',
            color: grey.A700,
            lineHeight: 1.2,
        },
    },
    palette: {
        // background: {
        //     paper: '#fdfdfd',
        // },
        primary: {
            light: '#7cabf1',
            main: '#2b599f',
            dark: '#1e3e6e',
            contrastText: '#fff',
        },
        secondary: {
            light: '#89ce9f',
            main: '#659574',
            dark: '#395441',
            contrastText: '#fff',
        },
        error: {
            main: red.A400,
        },
        info: {
            light: '#d8e0c9',
            main: '#d2e0b8',
            dark: '#93a17a',

        }
    },
    components: {
        MuiStack: {
            styleOverrides: {
                root: {
                    // padding: '0',
                    margin: '0 !important',
                },
            },
        },
    }

});

export default theme;
