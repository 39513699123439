import Grid from "@mui/material/Grid2";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {API_BASE_URL, TOKENS_URL, traits} from "../../utils/constants";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {SelectedTraits} from "../../models/trait";
import {useSearchParams} from "react-router-dom";
import {ApiResponse} from "../../models/ApiResponse";
import {Token} from "../../models/token";
import {snakeToCamelCase} from "../../utils/helpers";
import {useProps} from "@mui/x-data-grid/internals";
import TokensGallery from "../common/TokensGallery";
import {sanitizeSortModel} from "@mui/x-data-grid/hooks/features/sorting/gridSortingUtils";
import {GridSortModel} from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../theme";

interface CollectorsTokensProps {
    wallet: string;
}

export default function TokensTab(props: CollectorsTokensProps) {
    const {wallet} = useProps(props);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [totalTokens, setTotalTokens] = useState(0);
    const [loadedTokens, setLoadedTokens] = useState<Token[]>();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const page = useMemo(() => {
        return Number(searchParams.get('page')) || 1
    }, [searchParams]);

    const selectedTraits = useMemo<SelectedTraits>(() => {
        return {
            paper: searchParams.get('paper'),
            margins: searchParams.get('margins'),
            format: searchParams.get('format'),
            background: searchParams.get('background'),
            tree_choice: searchParams.get('tree_choice'),
            texture: searchParams.get('texture'),
            mention: searchParams.get('mention'),
            bark: searchParams.get('bark'),
            thread_flow: searchParams.get('thread_flow'),
            thread: searchParams.get('thread'),
        }
    }, [searchParams]);

    const paginationModel = useMemo<{ pageSize: number; page: number; }>(() => {
        return {
            pageSize: Number(searchParams.get('perPage')) || 25,
            page: parseInt(searchParams.get('page') || '0', 10),
        }
    }, [searchParams]);


    const sortModel = useMemo<GridSortModel>(() => {
        const orderParam = searchParams.get('order_by');
        if (orderParam == null) return [];
        const order = orderParam.substring(0, 1) === '-' ? 'desc' : 'asc'
        return [{field: order === "asc" ? orderParam : orderParam.substring(1, orderParam.length), sort: order}];
    }, [searchParams]);


    useEffect(() => {

        function getOrderByParam() {
            if (sortModel == null || sortModel.length == 0) return "";
            const orderBy = sortModel[0].sort === "asc" ? sortModel[0].field : "-" + sortModel[0].field;
            return `&order_by=${orderBy}`;
        }

        function getTokensSearchParams() {
            let urlSearchParams = "";
            for (const [trait, val] of Object.entries(selectedTraits)) {
                if (val != null) {
                    urlSearchParams += `&${trait}=${encodeURIComponent(val)}`
                }
            }
            return urlSearchParams;
        }

        const offset = `&offset=${(page - 1) * paginationModel.pageSize}`;

        if (wallet != null) {
            fetch(`${API_BASE_URL}${TOKENS_URL}?owner=${wallet}${getOrderByParam()}&limit=${paginationModel.pageSize}${offset}${getTokensSearchParams()}`)
                .then(response => response.json())
                .then((response: ApiResponse<Token[]>) => {
                    setTotalTokens(response.pagination.totalRowCount);
                    setLoadedTokens(response.data.map(token => snakeToCamelCase(token)));
                    setIsLoading(false);
                })
                .catch(e => console.error(e));
        }
    }, [selectedTraits, page]);

    const handleTokenFilterChange = (event: SelectChangeEvent, trait: string) => {
        if (event.target.value === "None") {
            searchParams.delete(trait);
        } else {
            searchParams.set(trait, event.target.value);
        }
        searchParams.delete("page")
        setSearchParams(searchParams);
    };

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12, sm: 12, md: 12}} height={"100%"}>
                <Stack
                    direction={'column'}
                    spacing={{xs: 1, sm: 2, md: 4}}
                    alignItems={"left"}
                    width={"100%"}
                >
                    <Accordion defaultExpanded={!isMobile} disableGutters>
                        <AccordionSummary
                            sx={{
                                minHeight: "0px",
                                ".MuiAccordionSummary-content": {margin: 0, padding: 0},
                                ".MuiAccordionSummary-gutters ": {margin: 0, padding: 0}
                            }}
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography gutterBottom variant="h6">
                                Filters
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction={"row"}
                                   useFlexGap
                                   sx={{flexWrap: 'wrap'}}
                            >
                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Paper-label">Paper</InputLabel>
                                    <Select
                                        value={selectedTraits.paper || undefined}
                                        label={selectedTraits.paper}
                                        onChange={(event) => handleTokenFilterChange(event, "paper")}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.paper.map(paper =>
                                            <MenuItem key={paper.value}
                                                      value={paper.value}>{paper.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Margins-label">Margins</InputLabel>
                                    <Select
                                        value={selectedTraits.margins || undefined}
                                        onChange={(event) => handleTokenFilterChange(event, "margins")}
                                        label={selectedTraits.margins}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.margins.map(margin =>
                                            <MenuItem key={margin.value}
                                                      value={margin.value}>{margin.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Format-label">Format</InputLabel>
                                    <Select
                                        value={selectedTraits.format || undefined}
                                        onChange={(event) => handleTokenFilterChange(event, "format")}
                                        label={selectedTraits.format}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.format.map(format =>
                                            <MenuItem key={format.value}
                                                      value={format.value}>{format.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Background-label">Background</InputLabel>
                                    <Select
                                        value={selectedTraits.background || undefined}
                                        onChange={(event) => handleTokenFilterChange(event, "background")}
                                        label={selectedTraits.background}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.background.map(bg =>
                                            <MenuItem key={bg.value}
                                                      value={bg.value}>{bg.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Tree-choice-label">Tree choice</InputLabel>
                                    <Select
                                        value={selectedTraits.tree_choice || undefined}
                                        onChange={(event) => handleTokenFilterChange(event, "tree_choice")}
                                        label={selectedTraits.tree_choice}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.tree_choice.map(choice =>
                                            <MenuItem key={choice.value}
                                                      value={choice.value}>{choice.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Texture-label">Texture</InputLabel>
                                    <Select
                                        value={selectedTraits.texture || undefined}
                                        onChange={(event) => handleTokenFilterChange(event, "texture")}
                                        label={selectedTraits.texture}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.texture.map(tx =>
                                            <MenuItem key={tx.value}
                                                      value={tx.value}>{tx.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Mention-label">Mention</InputLabel>
                                    <Select
                                        value={selectedTraits.mention || undefined}
                                        onChange={(event) => handleTokenFilterChange(event, "mention")}
                                        label={selectedTraits.mention}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.mention.map(mention =>
                                            <MenuItem key={mention.value}
                                                      value={mention.value}>{mention.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Bark-label">Bark</InputLabel>
                                    <Select
                                        value={selectedTraits.bark || undefined}
                                        onChange={(event) => handleTokenFilterChange(event, "bark")}
                                        label={selectedTraits.bark}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.bark.map(bark =>
                                            <MenuItem key={bark.value}
                                                      value={bark.value}>{bark.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Thread-flow-label">Thread Flow</InputLabel>
                                    <Select
                                        value={selectedTraits.thread_flow || undefined}
                                        onChange={(event) => handleTokenFilterChange(event, "thread_flow")}
                                        label={selectedTraits.thread_flow}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.thread_flow.map(flow =>
                                            <MenuItem key={flow.value}
                                                      value={flow.value}>{flow.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="Thread-label">Thread</InputLabel>
                                    <Select
                                        value={selectedTraits.thread || undefined}
                                        onChange={(event) => handleTokenFilterChange(event, "thread")}
                                        label={selectedTraits.thread}
                                        defaultValue="None"
                                    >
                                        <MenuItem value="None">
                                            <em>None</em>
                                        </MenuItem>
                                        {traits.thread.map(thread =>
                                            <MenuItem key={thread.value}
                                                      value={thread.value}>{thread.value}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Stack>
                {(!isLoading && loadedTokens != null) ?
                    <TokensGallery tokens={loadedTokens}
                                   totalTokens={totalTokens}
                                   paginationModel={paginationModel}
                                   sortModel={sanitizeSortModel(sortModel, true)}
                    /> :
                    <LinearProgress/>
                }
            </Grid>
        </Grid>
    );
}