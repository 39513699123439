import {
    Box,
    FormControl,
    ImageList,
    ImageListItemBar,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TablePagination,
    useMediaQuery
} from "@mui/material";
import {IMG_PREVIEW_URL, SINGLE_TOKEN_PATH} from "../../utils/constants";
import * as React from "react";
import {useMemo} from "react";
import {Token} from "../../models/token";
import {useNavigate, useSearchParams} from "react-router-dom";
import ImageListItem from '@mui/material/ImageListItem';
import styled from "@mui/system/styled";
import theme from "../../theme";
import TokenOverlay from "./TokenOverlay";
import {useProps} from "@mui/x-data-grid/internals";
import {GridSortModel} from "@mui/x-data-grid";
import Spacer from "../Spacer";

type TokensGalleryProps = {
    tokens?: Token[];
    totalTokens: number;
    paginationModel: { pageSize: number; page: number; };
    sortModel: GridSortModel;
}

const ImageListItemWithStyle = styled(ImageListItem)(() => ({
    "&:hover": {
        cursor: "pointer",
        opacity: 0.8,
        overflow: "hidden",
    },
}));

const ORDER_BY_OPTIONS = [
    {label: "Transactions Ascending", value: "num_transactions"},
    {label: "Transactions Descending", value: "-num_transactions"},
    {label: "Value Ascending", value: "last_price"},
    {label: "Value Descending", value: "-last_price"},
    {label: "ID Ascending", value: "id"},
    {label: "ID Descending", value: "-id"},
    {label: "Rarity descending", value: "rarity_rank"},
    {label: "Rarity ascending", value: "-rarity_rank"},
]


export default function TokensGallery(props: TokensGalleryProps) {
    const {totalTokens, paginationModel, tokens, sortModel} = useProps(props);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

    const onCardSelected = (event: React.MouseEvent, token: Token) => {
        navigate(`/${SINGLE_TOKEN_PATH}${token.id}`);
    };

    const orderBy = useMemo(() => {
        if (sortModel == null || sortModel.length == 0) return "-id";
        return sortModel[0].sort === "asc" ? sortModel[0].field : "-" + sortModel[0].field;
    }, [])

    function onPaginationModelChange(model: { pageSize: number; page: number; }) {
        if (paginationModel.pageSize != model.pageSize) {
            searchParams.set("perPage", String(model.pageSize));
            searchParams.delete("page");
        }
        if (model.page > 0) {
            searchParams.set("page", String(model.page));
        } else {
            searchParams.delete("page")
        }
        setSearchParams(searchParams);
    }

    const handleOrderChange = (event: SelectChangeEvent) => {
        if (event.target.value === "-id") {
            searchParams.delete("order_by");
        } else {
            searchParams.set("order_by", event.target.value);
        }
        searchParams.delete("page")
        setSearchParams(searchParams);
    };

    return (<Box sx={{width: "100%", height: "100%"}}>
        <Stack direction={{xs: "column", md: "row"}} alignItems="center" justifyContent="center">
            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                <InputLabel id="Paper-label">Order by:</InputLabel>
                <Select
                    value={orderBy}
                    label={"Order by:"}
                    onChange={(event) => handleOrderChange(event)}
                    defaultValue={(ORDER_BY_OPTIONS.find(opt => opt.value === '-id')!).label}
                >

                    {ORDER_BY_OPTIONS.map(option =>
                        <MenuItem key={option.value}
                                  value={option.value}>{option.label}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <Spacer/>
            <TablePagination component="div"
                             sx={{borderBottom: "none"}}
                             count={totalTokens}
                             page={paginationModel.page}
                             rowsPerPage={paginationModel.pageSize}
                             onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => onPaginationModelChange({
                                 ...paginationModel,
                                 page: newPage
                             })}
                             onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                 onPaginationModelChange({
                                     pageSize: Number(event.target.value),
                                     page: 0
                                 })
                             }}
                             rowsPerPageOptions={[25, 50, 100]}
            /></Stack>
        <ImageList cols={matchDownMd ? 2 : 4}
                   variant={"masonry"}
                   sx={{
                       width: "100%",
                       height: "100%",
                       // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                       transform: 'translateZ(0)',
                   }}
                   gap={1}
        >

            {tokens != null && tokens.map((token) => {
                return (
                    <ImageListItemWithStyle key={token.id}
                                            onClick={(e) => onCardSelected(e, token)}
                    >
                        <img
                            src={`${IMG_PREVIEW_URL}${token.id}.jpg`}
                            alt={`Aux Arbres #${token.id}`}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            sx={{
                                "& .MuiImageListItemBar-titleWrap": {
                                    paddingTop: 0,
                                    paddingLeft: "3px",
                                    paddingRight: "3px"
                                },
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, ' +
                                    'rgba(0,0,0,0.2) 70%, rgba(0,0,0,0) 100%)',
                            }}
                            title={<TokenOverlay token={token}/>}
                            position="top"
                            actionPosition="left"
                        >
                        </ImageListItemBar>
                    </ImageListItemWithStyle>)
            })}
        </ImageList>
        <TablePagination component="div"
                         sx={{borderBottom: "none"}}
                         count={totalTokens}
                         page={paginationModel.page}
                         rowsPerPage={paginationModel.pageSize}
                         onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => onPaginationModelChange({
                             ...paginationModel,
                             page: newPage
                         })}
                         onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                             onPaginationModelChange({
                                 pageSize: Number(event.target.value),
                                 page: 0
                             })
                         }}
                         rowsPerPageOptions={[25, 50, 100]}
        /></Box>)
}