import * as React from "react";
import {useEffect, useState} from "react";
import {Bar, BarChart, Label, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import theme from "../../../theme";
import {Box, Card, CardActions, CardContent, Divider, LinearProgress, Stack, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import {API_BASE_URL, COLLECTORS_PATH, TOKEN_FREQUENCY_URL} from "../../../utils/constants";
import {ApiResponse} from "../../../models/ApiResponse";

const proportionDesktop = 4;

interface TokenFrequency {
    tokens: number;
    collectors: number;
}

function CollectorsTooltip(pl: any) {
    const {active, payload} = pl;

    if (active && payload?.[0]?.payload != null) {
        const pld = payload[0].payload;
        return (<>
            <Typography sx={{paddingLeft: 1}}> Tokens owned: {`${pld.tokens}`}</Typography>
            <Typography
                sx={{
                    paddingLeft: 1,
                    color: theme.palette.secondary.main
                }}> Collectors: {`${pld.collectors}`}</Typography>
        </>);
    }
    return null;
}

export default function CollectorsPreview() {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [isLoading, setIsLoading] = useState(false);
    const [loadedData, setLoadedData] = useState<TokenFrequency[]>([]);

    useEffect(() => {
        setIsLoading(true);
        fetch(`${API_BASE_URL}${TOKEN_FREQUENCY_URL}`)
            .then(response => response.json())
            .then((response: ApiResponse<TokenFrequency[]>) => {
                setLoadedData(response.data);
                setIsLoading(false);
            })
            .catch(e => console.error(e)).finally(() => setIsLoading(false));

    }, []);
    return (
        <>
            <Divider sx={{paddingTop: "20px"}} id={"collectors"}>
                <Typography variant={"button"}> Collectors</Typography>
            </Divider>

            <Card>
                <CardContent sx={{paddingTop: 0, paddingBottom: 0}}>
                    <Box component="section" sx={{width: "100%", color: theme.palette.primary.light}}>
                        <ResponsiveContainer width="99%" aspect={isMobile ? 1.5 : proportionDesktop}>
                            {isLoading ? <LinearProgress/> :
                                <BarChart data={loadedData} margin={{bottom: 10, right: 5}}>
                                    <Bar dataKey="collectors"
                                         fill={theme.palette.secondary.main}
                                         isAnimationActive={false}/>
                                    <XAxis dataKey="tokens">
                                        <Label value="Tokens owned" offset={-5} position={"insideBottomRight"}/>
                                        <Label value="Tokens owned" offset={-5} position={"insideBottomLeft"}/>
                                    </XAxis>

                                    <YAxis type="number" scale={'log'} domain={[0.5, "auto"]}
                                           tickFormatter={(tick) => (tick < 1 ? '0' : tick)}>
                                        <Label
                                            value={'Collectors'}
                                            angle={-90}
                                            offset={1}
                                            dx={-20}
                                            dy={20}
                                        /></YAxis>

                                    <ReferenceLine x={100} stroke={theme.palette.secondary.main} strokeDasharray="3 3">
                                        <Label value="Allowed max mints 100" position={"insideTopRight"}/>
                                    </ReferenceLine>
                                    <Tooltip content={CollectorsTooltip}/>
                                </BarChart>}
                        </ResponsiveContainer>
                    </Box>

                </CardContent>
                <CardActions>
                    <Grid container width={"100%"} columns={{xs: 12, sm: 12, md: 12}} spacing={3}>
                        <Grid size={{xs: 12, sm: 8, md: 8}} paddingLeft={"10px"}>
                            <Typography>Even though minting Aux Arbres was inexpensive, there was a limit to how many
                                NFTs a single wallet could mint. This encouraged the most eager collectors to create
                                multiple wallets in order to acquire more NFTs than they were otherwise allowed. This
                                explains the high number of transfers following the minting process. Over time, we can
                                observe a gradual reduction in the number of holders, as tokens were consolidated into
                                fewer wallets. Of course, this isn't the only type of operation that has taken place. We
                                have already analyzed the trades in the transactions section of this website. Here, we
                                will focus on the collectors: how many tokens they hold and which specific ones.
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 4, md: 4}} display="flex" alignItems="flex-start">
                            <Stack sx={{width: '100%'}}>
                                <Button variant="contained" disableElevation href={`/${COLLECTORS_PATH}`}>
                                    Meet the collectors
                                </Button>
                            </Stack>

                        </Grid>
                    </Grid>
                </CardActions>
            </Card>


        </>
    );
}
