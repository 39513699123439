import {Box, Link, List, ListItemButton, ListItemText} from "@mui/material";
import {traits} from "../../../utils/constants";
import * as React from "react";

export default function TextureTooltip(props: { selectedTexture: string | undefined }) {
    return <Box width={"300px"}>
        <List sx={{padding: 0}}>

            {traits.texture.map(texture => {
                return (
                    <ListItemButton component={Link}
                                    href={`/tokens?order_by=id&texture=${(traits.texture.find(t => t.value === texture.value))?.value}`}
                                    disableGutters
                                    sx={{
                                        marginLeft: (texture.value == props.selectedTexture) ? "-9px" : 0,
                                        width: "100%",
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        backgroundColor: (texture.value == props.selectedTexture) ? "grey" : "inherit"
                                    }}>
                        <ListItemText primary={texture.value}
                                      sx={{
                                          width: "40px",
                                          marginLeft: (texture.value == props.selectedTexture) ? "15px" : 0
                                      }}
                        />
                        <ListItemText primary={String(texture.occurrence)}
                        />
                    </ListItemButton>
                )
            })}
        </List>
    </Box>;
}
