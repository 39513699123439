import {Box, Link, List, ListItemButton, ListItemText} from "@mui/material";
import {traits} from "../../../utils/constants";
import * as React from "react";

export default function PaperToottip(props: { selectedPaper: string | undefined }) {
    return <Box width={"300px"}>
        <List sx={{padding: 0}}>

            {traits.paper.map(trait => {
                return (
                    <ListItemButton component={Link}
                                    href={`/tokens?order_by=id&paper=${(traits.paper.find(p => p.value === trait.value))?.value}`}
                                    disableGutters
                                    sx={{
                                        marginLeft: (trait.value == props.selectedPaper) ? "-9px" : 0,
                                        width: "100%",
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        backgroundColor: (trait.value == props.selectedPaper) ? "grey" : "inherit"
                                    }}>
                        <ListItemText primary={trait.value}
                                      sx={{
                                          width: "40px",
                                          marginLeft: (trait.value == props.selectedPaper) ? "9px" : 0
                                      }}
                        />
                        <ListItemText primary={String(trait.occurrence)}
                        />
                    </ListItemButton>
                )
            })}
        </List>
    </Box>;
}
