import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import theme from "./theme";
import {ThemeProvider} from "@mui/material";

// @ts-ignore
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ThemeProvider theme={theme}>
        <App/>
    </ThemeProvider>
);
