import {Box, Link, List, ListItemButton, ListItemText} from "@mui/material";
import {traits} from "../../../utils/constants";
import * as React from "react";

export default function ThreadTooltip(props: { selectedThread: string | undefined }) {
    return <Box width={"300px"}>
        <List sx={{padding: 0}}>

            {traits.thread.map(thread => {
                return (
                    <ListItemButton component={Link}
                                    href={`/tokens?order_by=id&thread=${(traits.thread.find(t => t.value === thread.value))?.value}`}
                                    disableGutters
                                    sx={{
                                        marginLeft: (thread.value == props.selectedThread) ? "-9px" : 0,
                                        width: "100%",
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        backgroundColor: (thread.value == props.selectedThread) ? "grey" : "inherit"
                                    }}>
                        <ListItemText primary={thread.value}
                                      sx={{
                                          width: "40px",
                                          marginLeft: (thread.value == props.selectedThread) ? "15px" : 0
                                      }}
                        />
                        <ListItemText primary={String(thread.occurrence)}
                        />
                    </ListItemButton>
                )
            })}
        </List>
    </Box>;
}
