import * as React from 'react';
import {PageContainer} from '@toolpad/core/PageContainer';
import {AppProvider} from '@toolpad/core/AppProvider';
import {useTheme} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {Outlet} from "react-router-dom";
import Header from "./features/Header";
import {Stack} from "@mui/material";
import MainMenu from "./features/MainMenu";


export default function Layout() {

    const theme = useTheme();

    return (
        <AppProvider theme={theme}>
            {/*this is a hack to remove margin above page container but there is another one above menu on an empty div*/}
            <Paper sx={{width: '100%', "& .MuiStack-root": {marginTop: 0}}} variant={"outlined"}>
                <PageContainer disableGutters>
                    <Header/>
                    <Outlet/>
                </PageContainer>
                {/*---------------------footer--------------------*/}
                <Stack alignItems={{xs: 'inherit', md: 'center'}} direction={'column'}>
                    <MainMenu/>
                </Stack>
            </Paper>
        </AppProvider>
    );
}