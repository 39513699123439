import * as React from 'react';
import hexColors from '../utils/hex_colors_sliced';
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid2";

type ColorBoxesProps = {
    maxHeight: number;
}

export default function ColorBoxes(props: ColorBoxesProps) {
    const colorArray = hexColors.split(',');


    return (
        <Grid container spacing={0.5}   sx={{
                    // backgroundColor: alpha('#fff', 0.7),
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}>
            {colorArray.map((hexColor, index) => <Box
                sx={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: hexColor
                }}
                key={index + hexColor}> </Box>)}
        </Grid>
    );
}
